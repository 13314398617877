import { useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row, Select, Switch } from "antd";

import Tabs from "@components/Tabs";
import JobInfo from "./JobInfo";
import SalaryOfferModeller from "./SalaryOfferModeller";
import Button from "@components/Button";
import CandidateDetails from "../CandidateDetails";
import OfferDetails from "../OfferDetails";
import InternalComparatorData from "./InternalComparatorData";
import ExternalComparatorData from "./ExternalComparatorData";
import LongTermIncentiveData from "./LongTermIncentiveData";
import { offersApi, useFetchCurrenciesQuery } from "@services";
import { ICurrency } from "@types";
import { convertOffer, handleCurrency, handleStage, removeCurrency } from "@store/offers";
import { useTypedSelector } from "@hooks";
import CustomButton from "@/components/customButton";

const { Option } = Select;

interface IComponent {
  1: JSX.Element;
  2: JSX.Element;
  3: JSX.Element;
  4: JSX.Element;
}

const OfferModeller = ({
  offerModeller,
  onResponse,
  detailedView,
}: {
  offerModeller?: boolean;
  onResponse: any;
  detailedView: any;
}) => {
  const dispatch = useDispatch();
  const { company } = useTypedSelector(state => state.auth?.user!);

    const {
      offer_annual,
    } = useTypedSelector(state => state.offers);

  const { id: company_id } = company!;
  const [selectedTab, setSelectedTab] = useState(4);
  const [internalData, setInternalData] = useState(false);
  const [externalData, setExternalData] = useState(false);
  const [ltiData, setLtiData] = useState(false);
  const { currencyName, offer } = useTypedSelector(state => state?.offers);
  const [isAnnual, setIsAnnual] = useState(true);

  const { data: currenciesData, isLoading: isFetchingCurrencies } =
    useFetchCurrenciesQuery(null);
  const { data: currencies } = currenciesData || {};

  const onClickTab = (id: number) => setSelectedTab(id);

  return (
    <div className="offers__form__offerModeller">
      <InternalComparatorData
        isVisible={internalData}
        setIsVisible={setInternalData}
      />

      <ExternalComparatorData
        isVisible={externalData}
        setIsVisible={setExternalData}
      />

      <LongTermIncentiveData isVisible={ltiData} setIsVisible={setLtiData} />

      {offer?.status === "DRAFTED" && offer?.stage === "OFFER_MODELLER" && (
        <div>
          <div className="my-6">
            <JobInfo />
          </div>
          <div className="my-6">
            <CandidateDetails
              onResponse={onResponse}
              detailedView={true}
              offerModeller={true}
            />
          </div>
          <div className="my-6">
            <OfferDetails
              onResponse={onResponse}
              offerModeller={true}
              detailedView={true}
            />
          </div>
        </div>
      )}

      {selectedTab === 4 ? (
        <div className="jobCreationBar bg-white px-4 py-4 rounded-lg flex justify-between items-center ">
          <div className="w-8/12 flex items-center justify-between">
            <CustomButton btnName="" onClick={() => setLtiData(true)}>
              {`Long Term Incentives - Grade ${
                offer?.offer_position_detail?.grade || ""
              }`}
            </CustomButton>
            <CustomButton btnName="" onClick={() => setExternalData(true)}>
              External Comparator Data
            </CustomButton>
            <CustomButton btnName="" onClick={() => setInternalData(true)}>
              Interal Comparator Data
            </CustomButton>
          </div>

          <div>
            <Switch
              checked={offer_annual}
              onChange={() => dispatch(convertOffer())}
              checkedChildren="Annual"
              unCheckedChildren="Monthly"
            />
          </div>
          <div className="w-3/12">
            <Select
              value={currencyName || undefined}
              loading={isFetchingCurrencies}
              allowClear={true}
              onClear={() => dispatch(removeCurrency())}
              onChange={(currency: string) => {
                if (currency !== undefined) {
                  const { id, code, name } = JSON.parse(currency);
                  dispatch(
                    handleCurrency({
                      id,
                      code: `${name} (${code})`,
                    })
                  );
                  dispatch(
                    offersApi.endpoints.fetchCurrencyRate.initiate(
                      {
                        code: id,
                        company_id,
                        offer_id: offer?.id,
                      },
                      {
                        subscribe: false,
                        forceRefetch: true,
                      }
                    )
                  );
                }
              }}
              showArrow={true}
              showSearch={true}
              size="large"
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Convert Currency"
            >
              {currencies
                ?.slice()
                ?.sort(function (a: any, b: any) {
                  if (a.name < b.name) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }
                  return 0;
                })
                ?.map((currency: ICurrency) => (
                  <Option key={currency.id} value={JSON.stringify(currency)}>
                    {`${currency.name} (${currency.code})`}
                  </Option>
                ))}
            </Select>
          </div>
        </div>
      ) : null}

      <div className="my-6">
        <SalaryOfferModeller />
      </div>
    </div>
  );
};

export default OfferModeller;
