import { FC } from "react";
import { Col, Input, Row } from "antd";
import { useTypedSelector } from "@hooks";

import Modal from "@components/Modal";
import { IModal } from "@types";
import { useFetchComparatorDataQuery } from "@/services";

const InternalComparatorData: FC<IModal> = ({ isVisible, setIsVisible }) => {
  const { offer } = useTypedSelector(state => state?.offers);
  const { company } = useTypedSelector(state => state.auth?.user!);
  const { id: company_id } = company!;
  const { data } = useFetchComparatorDataQuery(
    {
      company_id,
      offer_id: offer?.id,
    },
    { skip: !offer?.id }
  );
  const { data: comparatorData } = data || {};
  const { internal_comparator_data } = comparatorData || {};
  const {
    internal_salary_range,
    internal_payroll_grade,
    internal_payroll_function,
  } = internal_comparator_data || {};

  
  const formatNumberWithCommas = (number: any): string | null => {
    // Check if the number is null, undefined, or NaN, or if it's an empty string
    if (number == null || isNaN(Number(number)) || number === "") return null;

    // Convert input to a number if it's a string
    const numericValue = Number(number);

    // If the number is zero, return "0" explicitly
    if (numericValue === 0) return "";

    // Return the formatted number with commas using toLocaleString
    return numericValue.toLocaleString();
  };

  return (
    <Modal
      mode="versions"
      footer={null}
      title="Interal Comparator Data"
      isVisible={isVisible}
      width={855}
      setIsVisible={setIsVisible}
    >
      <div className="px-10 py-10">
      <div className="offers__modal__row">
          <div className="offers__modal__title">
            {`Internal Salary Range - Grade ${offer?.offer_position_detail?.grade}`}
          </div>
          <Row justify="space-between" className="mb-10">
            <Col span={7}>
              <label>Minimum</label>
              <Input
                readOnly
                value={ formatNumberWithCommas(internal_salary_range?.min || "")}
                placeholder="minimum value"
                size="large"
              />
            </Col>
            <Col span={7}>
              <label>Midpoint</label>
              <Input
                readOnly
                value={ formatNumberWithCommas(internal_salary_range?.mid || "")}
                placeholder="midpoint value"
                size="large"
              />
            </Col>
            <Col span={7}>
              <label>Maximum</label>
              <Input
                readOnly
                value={ formatNumberWithCommas(internal_salary_range?.max || "")}
                placeholder="maximum value"
                size="large"
              />
            </Col>
          </Row>
        </div>
        <div className="offers__modal__row">
          <div className="offers__modal__title">
            {`Internal Payroll Spread - Grade ${offer?.offer_position_detail?.grade}`}
          </div>
          <Row justify="space-between" className="mb-10">
            <Col span={7}>
              <label>Minimum</label>
              <Input
                readOnly
                value={formatNumberWithCommas(internal_payroll_grade?.min || "")}
                placeholder="minimum value"
                size="large"
              />
            </Col>
            <Col span={7}>
              <label>Midpoint</label>
              <Input
                readOnly
                value={formatNumberWithCommas(internal_payroll_grade?.avg || "")}
                placeholder="midpoint value"
                size="large"
              />
            </Col>
            <Col span={7}>
              <label>Maximum</label>
              <Input
                readOnly
                value={formatNumberWithCommas(internal_payroll_grade?.max || "")}
                placeholder="maximum value"
                size="large"
              />
            </Col>
          </Row>
        </div>
        <div className="offers__modal__row">
          <div className="offers__modal__title">
            Internal Payroll Spread - Functional
          </div>
          <Row justify="space-between" className="mb-10">
            <Col span={7}>
              <label>Minimum</label>
              <Input
                readOnly
                value={formatNumberWithCommas(internal_payroll_function?.min || "")}
                placeholder="minimum value"
                size="large"
              />
            </Col>
            <Col span={7}>
              <label>Midpoint</label>
              <Input
                readOnly
                value={formatNumberWithCommas(internal_payroll_function?.avg || "")}
                placeholder="midpoint value"
                size="large"
              />
            </Col>
            <Col span={7}>
              <label>Maximum</label>
              <Input
                readOnly
                value={formatNumberWithCommas(internal_payroll_function?.max || "")}
                placeholder="maximum value"
                size="large"
              />
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
};

export default InternalComparatorData;
