import { createApi } from "@reduxjs/toolkit/query/react";

import { tomService } from "./restService";

export const offersApi = createApi({
  reducerPath: "offersApi",
  baseQuery: tomService({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/company`,
  }),
  tagTypes: ["Offers"],
  endpoints: builder => ({
    fetchOffers: builder.query({
      query: ({ company_id, status, page = 1 }) => ({
        url: `/${company_id}/offers/?statuses=${status}&page=${page}`,
        method: "GET",
      }),
      providesTags: ["Offers"],
    }),
    fetchComparatorData: builder.query({
      query: ({ company_id, offer_id }) => ({
        url: `/${company_id}/offer/${offer_id}/comparator-data/`,
        method: "GET",
      }),
    }),
    fetchAutoPopulateData: builder.query({
      query: ({ company_id, offer_id }) => ({
        url: `/${company_id}/offer/${offer_id}/auto-populate-data/`,
        method: "GET",
      }),
    }),
    fetchCurrencyRate: builder.query({
      query: ({ offer_id, company_id, code }) => {
        return {
          url: `/${company_id}/offer/${offer_id}/currency/${code}`,
          method: "GET",
        };
      },
    }),

    fetchOfferCurrencyRate: builder.query({
      query: ({ offer_id, company_id, code }) => {
        return {
          url: `/${company_id}/offer/${offer_id}/currency/${code}`,
          method: "GET",
        };
      },
    }),

    fetchStiCurrencyRate: builder.query({
      query: ({ offer_id, company_id, code }) => {
        return {
          url: `/${company_id}/offer/${offer_id}/currency/${code}`,
          method: "GET",
        };
      },
    }),

    fetchOfferById: builder.query({
      query: ({ company_id, id }) => ({
        url: `/${company_id}/offer/${id}/`,
        method: "GET",
      }),
    }),
    createOfferDetails: builder.mutation({
      query: ({ company_id, body }) => ({
        url: `/${company_id}/offer/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Offers"],
    }),
    updateOfferDetails: builder.mutation({
      query: ({ company_id, body, id }) => ({
        url: `/${company_id}/offer/${id}/position-detail/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Offers"],
    }),
    createCandidateDetails: builder.mutation({
      query: ({ company_id, id, body }) => ({
        url: `/${company_id}/offer/${id}/candidate-detail/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Offers"],
    }),
    createOfferModeller: builder.mutation({
      query: ({ company_id, id, body }) => ({
        url: `/${company_id}/offer/${id}/offer-modeller/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Offers"],
    }),
    updateOfferStatus: builder.mutation({
      query: ({ company_id, id, status }) => ({
        url: `/${company_id}/offer/${id}/?status=${status}`,
        method: "PATCH",
      }),
      invalidatesTags: ["Offers"],
    }),
    sendEmail: builder.mutation({
      query: ({ company_id, id, status }) => ({
        url: `/${company_id}/offer/${id}/email-template/`,
        method: "POST",
      }),
      invalidatesTags: ["Offers"],
    }),
    fetchEmailPreview: builder.query({
      query: ({ company_id, id }) => ({
        url: `/${company_id}/offer/${id}/email-template/`,
        method: "GET",
       
      }),
     
    }),
    
    
    
  }),
});
export const {
  useFetchOffersQuery,
  useFetchComparatorDataQuery,
  useCreateCandidateDetailsMutation,
  useCreateOfferDetailsMutation,
  useFetchOfferByIdQuery,
  useUpdateOfferDetailsMutation,
  useFetchCurrencyRateQuery,
  useFetchOfferCurrencyRateQuery,
  useCreateOfferModellerMutation,
  useUpdateOfferStatusMutation,
  useFetchAutoPopulateDataQuery,
  useFetchStiCurrencyRateQuery,
  useSendEmailMutation,
  useFetchEmailPreviewQuery
} = offersApi;
