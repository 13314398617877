import { Col, message, Row } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Table from "@components/Table";
import { getColumns } from "./config";
import {
  ErrorServices,
  salaryRangeApi,
  useDeleteSalaryRangeMutation,
  useFetchCompanySalaryRangesQuery,
  useUploadSalaryRangesMutation,
} from "@services";
import AddSalaryRange from "./AddSalaryRange";
import { ISalaryRange } from "@types";
import SalaryRangeVersions from "./Versions";
import Upload from "@components/Upload";
import { useTypedSelector } from "@hooks";
import { checkPermission } from "@/utils";
import { permissions } from "@/router";
import CustomButton from "@/components/customButton";
import { MdOutlineFileDownload, MdOutlineFileUpload } from "react-icons/md";
import SearchBox from "@/components/searchInput";

const SalaryRange = () => {
  const { company } = useTypedSelector(state => state.auth?.user!);
  const { id: company_id } = company!;
  const dispatch = useDispatch();
  const salary_range_id = useRef<any>(null);
  const [page, setPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");

  const [isVisible, setIsVisible] = useState(false);
  const [isUploadModal, setIsUploadModal] = useState(false);
  const [selectedSalaryRange, setSelectedSalaryRange] =
    useState<ISalaryRange | null>(null);
  const [isVersionsModal, setIsVersionsModal] = useState(false);
  const { data: salaryRanges, isLoading } = useFetchCompanySalaryRangesQuery({
    company_id,
    page,
  });
  const { data, pagination } = salaryRanges || {};
  const [deleteSalaryRange, { isLoading: isDeleting }] =
    useDeleteSalaryRangeMutation();
  const [uploadFile, setUploadFile] = useState({
    file: null,
    active: false,
  });

  const [uploadJobGrades, { isLoading: isUploading }] =
    useUploadSalaryRangesMutation();

  const editSalaryRange = (salaryRange: ISalaryRange) => {
    setSelectedSalaryRange(salaryRange);
    setIsVisible(true);
  };

  const formatNumberWithCommas = (number: any): string | null => {
    // Check if the number is null, undefined, or NaN, or if it's an empty string
    if (number == null || isNaN(Number(number)) || number === "") return null;

    // Convert input to a number if it's a string
    const numericValue = Number(number);

    // If the number is zero, return "0" explicitly
    if (numericValue === 0) return "";

    // Return the formatted number with commas using toLocaleString
    return numericValue.toLocaleString();
  };


 const updatedData= data &&
    data.map((item: any) => ({
      ...item,
      salary_mid: formatNumberWithCommas(item.salary_mid) || item.salary_mid,
      salary_min: formatNumberWithCommas(item.salary_min) || item.salary_min,
      salary_max: formatNumberWithCommas(item.salary_max) || item.salary_max,
    }));

  const removeSalaryRange = async (id: number) => {
    try {
      salary_range_id.current = id;
      await deleteSalaryRange({ company_id, id }).unwrap();
      message.success("Salary range deleted successfully!");
    } catch (error) {
      ErrorServices(error);
    }
  };

  const handleUpload = async () => {
    const { file, active }: { file: any; active: boolean } = uploadFile;
    setIsUploadModal(false);
    try {
      const formData = new FormData();
      formData.append("attachment", file!, file?.name!);
      const res = await uploadJobGrades({
        company_id,
        active,
        body: formData,
      }).unwrap();
      message.success(res?.message);
    } catch (error: any) {
      message.error(error?.message);
      console.log(error);
    }
  };

  const download = (id?: number) => {
    dispatch(
      salaryRangeApi.endpoints.downloadSalaryRanges.initiate(
        {
          company_id,
          version_id: id || null,
        },
        {
          subscribe: false,
          forceRefetch: true,
        }
      )
    );
  };

  const columns = getColumns({
    editSalaryRange,
    isDeleting,
    salary_range_id,
    removeSalaryRange,
  });



  return (
    <>
      {isUploadModal && (
        <Upload
          title="Upload Salary Ranges"
          onSubmit={handleUpload}
          isVisible={isUploadModal}
          setIsVisible={setIsUploadModal}
          file={uploadFile}
          setFile={setUploadFile}
        />
      )}
      {isVisible && (
        <AddSalaryRange
          selectedSalaryRange={selectedSalaryRange}
          setSelectedSalaryRange={setSelectedSalaryRange}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
        />
      )}
      {isVersionsModal && (
        <SalaryRangeVersions
          isVisible={isVersionsModal}
          setIsVisible={setIsVersionsModal}
          download={download}
        />
      )}

      <div className="bg-themeCore ">
        <div className="jobCreationBar bg-white px-4 py-6 rounded-lg flex justify-between items-center ">
          <div className="title text-themeBlue  font-medium text-3xl">
            Salary Range
          </div>

          <div className="flex flex-row justify-between">
            {checkPermission(permissions.UPDATE_SALARY_RANGE) ||
            checkPermission(permissions.CREATE_SALARY_RANGE) ? (
              <CustomButton
                isLoading={isUploading}
                onClick={() => setIsUploadModal(true)}
                btnName="Upload"
                customClasses="shadow-md font-medium border py-1 px-6 !border-themeBorder"
                iconPosition="left"
              >
                <MdOutlineFileUpload
                  size={20}
                  className="text-white font-bold"
                />
              </CustomButton>
            ) : null}

            <CustomButton
              onClick={() => download()}
              btnName="Download"
              customClasses="shadow-md font-medium border py-1 px-6 !border-themeBorder ml-4"
              iconPosition="right"
            >
              <MdOutlineFileDownload
                size={20}
                className="text-white font-bold"
              />
            </CustomButton>

            <CustomButton
              onClick={() => setIsVersionsModal(true)}
              btnName="Versions"
              customClasses="shadow-md font-medium border py-1 px-6 !border-themeBorder ml-4"
              iconPosition="left"
            ></CustomButton>
            {checkPermission(permissions.CREATE_SALARY_RANGE) && (
              <CustomButton
                onClick={() => setIsVisible(true)}
                btnName="Create Salary Range"
                customClasses="shadow-md font-medium border py-1 px-6 !border-themeBorder ml-4"
                iconPosition="left"
              ></CustomButton>
            )}
          </div>
        </div>
      </div>

      <div className="jobCreationListing mt-10 bg-white px-14 py-6 rounded-lg">
        <SearchBox
          placeholder="Type to search..."
          value={searchInput}
          onChange={value => setSearchInput(value)}
        />
        <Table
          isLoading={isLoading}
          data={updatedData}
          columns={columns}
          scroll={1300}
          pagination={true}
          count={pagination?.count}
          onChangePage={setPage}
          page={page}
        />
      </div>
    </>
  );
};

export default SalaryRange;
