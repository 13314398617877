import { useDispatch } from "react-redux";
import { Row, Col, Input, Select } from "antd";
import _ from "lodash";

import { ReactComponent as PlusIcon } from "@assets/images/plus-with-bg.svg";
import { useTypedSelector } from "@hooks";
import { handleOfferModeller } from "@store/offers";
import { getCurrencyTotal, round } from "@utils";
import { useEffect, useState } from "react";
const { Option } = Select;

const LongTermIncentives = () => {
  const dispatch = useDispatch();

  const {
    offerModeller: { offer_lti, offer_sti, offer_fixed_cash },
    currencyRate,
    currencyName,
    offer,
    stock_data,
  } = useTypedSelector(state => state.offers);

  const readOnly = offer && offer?.status !== "DRAFTED" ? true : false;
  const currencyCode = currencyName?.split("(")[1]?.replace(")", "") || "";

  const [stock, setStock] = useState(null);

  const labelColSpan = currencyCode ? 10 : 12;
  const currencyColSpan = currencyCode ? 7 : 12;
  const { current_currency_total, proposed_currency_total } = getCurrencyTotal(
    offer_lti,
    currencyRate,
    []
  );

  const handleChange = (event: any) => {

    let val = event.target.value.replace(/,/g, '');
    dispatch(
      handleOfferModeller({
        key: "offer_lti",
        data: {
          [event.target.name]: +val || null,
        },
      })
    );
  };

  const addMoreLocally = (is_current: boolean) => {
    const newCurrent = {
      is_current,
      value: null,
      name: null,
      id: +_.uniqueId(),
      readonly: true,
    };
    dispatch(
      handleOfferModeller({
        key: "offer_lti",
        data: {
          other_ltis: [...offer_lti.other_ltis, newCurrent],
        },
      })
    );
  };

  const addMoreAdhocLocally = (is_current: boolean) => {
    const newCurrent = {
      is_current,
      value: null,
      name: null,
      id: +_.uniqueId(),
      readonly: true,
      unit: true,
    };
    dispatch(
      handleOfferModeller({
        key: "offer_lti",
        data: {
          other_ltis: [...offer_lti.other_ltis, newCurrent],
        },
      })
    );
  };

  const handleChangeNewField = (
    id: number,
    value: number | string,
    type: "name" | "value"
  ) => {
    dispatch(
      handleOfferModeller({
        key: "offer_lti",
        data: {
          other_ltis: offer_lti?.other_ltis?.map(lti =>
            lti?.id === id ? { ...lti, [type]: value } : lti
          ) as any,
        },
      })
    );
  };

  const removeField = (id: number) => {
    dispatch(
      handleOfferModeller({
        key: "offer_lti",
        data: {
          other_ltis: offer_lti?.other_ltis?.map(lti =>
            lti?.id === id ? { ...lti, readonly: false } : lti
          ) as any,
        },
      })
    );
  };

  const removeBonus = (id: number) => {
    dispatch(
      handleOfferModeller({
        key: "offer_lti",
        data: {
          other_ltis: offer_lti?.other_ltis?.filter(
            lti => lti?.id !== id
          ) as any,
        },
      })
    );
  };

  const proposedTotal =
    (offer_fixed_cash?.proposed_total || 0) +
    (offer_sti?.proposed_total_bonus || 0) +
    (offer_lti?.proposed_total_lti || 0);
  const currentTotal =
    (offer_fixed_cash?.current_total || 0) +
    (offer_sti?.current_bonus_target || 0) +
    (offer_lti?.current_total_lti || 0);

  let percentageDifference = 0;

  if (proposedTotal > 0 && currentTotal > 0) {
    percentageDifference = (proposedTotal / currentTotal - 1) * 100;
  }

  const finalPercentage =
    percentageDifference > 0 ? percentageDifference.toFixed(0) : 0;

  let getLtiTotal = () => {
    var totalLtis = offer_lti?.proposed_total_lti;

    // Iterate over the filtered array
    offer_lti?.other_ltis
      ?.filter(({ is_current }) => !is_current)
      .forEach(({ value, unit }) => {
        // If unit is missing and value is present, update totalLtis
        if (!unit && value) {
          totalLtis = totalLtis - value;
          totalLtis += round(value * (stock || 1));
        }
      });

    return round(totalLtis);
  };


  
  const formatNumberWithCommas = (number: any): string | null => {
    // Check if the number is null, undefined, or NaN, or if it's an empty string
    if (number == null || isNaN(Number(number)) || number === "") return null;
  
    // Convert input to a number if it's a string
    const numericValue = Number(number);
  
    // If the number is zero, return "0" explicitly
    if (numericValue === 0) return "";
  
    // Return the formatted number with commas using toLocaleString
    return numericValue.toLocaleString();
  };
  


  return (
    <>
      <div className=" flex justify-end items-center mr-6">
        <Select
          className="custom-select rounded-none w-3/12"
          disabled={!stock_data}
          size="large"
          showArrow
          placeholder="Select Stock"
          showSearch={false}
          onChange={item => {
            setStock(item);
          }}
          value={stock || undefined}
        >
          {stock_data &&
            stock_data?.map(({ id, stock_value, tracking_id }) => (
              <Option key={id} value={stock_value}>
                {tracking_id}
              </Option>
            ))}
        </Select>
      </div>

      <Row className="salary-offer">
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__header-row">
            <Col span={12}>
              <div className="sub-heading">Current</div>
            </Col>
          </Row>
          <Row className="salary-offer__content-row">
            {/* Current-Cols */}
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field-upper"></div>

              <div className="salary-offer__content-row__field">
                <label>Unvested Equity</label>
              </div>
              <div className="salary-offer__content-row__field">
                <label>Annual Grant</label>
              </div>

              {offer_lti?.other_ltis
                ?.filter(({ is_current }) => is_current)
                ?.map(({ name, id, readonly }) => (
                  <div key={id} className="salary-offer__content-row__field">
                    {name?.length && !readonly! ? (
                      <label>{name}</label>
                    ) : (
                      <Input
                        readOnly={readOnly}
                        autoFocus
                        onChange={e =>
                          handleChangeNewField(id!, e?.target?.value, "name")
                        }
                        onKeyDown={e => {
                          if (e.key === "Enter") {
                            removeField(id!);
                          }
                        }}
                        value={name || ""}
                        placeholder="Value here..."
                        size="large"
                      />
                    )}
                    {!readOnly && (
                      <div
                        className="salary-offer__content-row__field__remove"
                        onClick={() => removeBonus(id!)}
                      >
                        <PlusIcon fill="#000" />
                      </div>
                    )}
                  </div>
                ))}

              {!readOnly ? (
                <div
                  onClick={() => addMoreLocally(true)}
                  className="salary-offer__content-row__field--add-more"
                >
                  <PlusIcon fill="#4FAA89" />
                  <span>Add more</span>
                </div>
              ) : (
                <div className="salary-offer__content-row__field--add-more" />
              )}

              {/* <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <label>Total LTIs</label>
              </div> */}
            </Col>
            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div className="salary-offer__content-row__field-upper">
                {offer?.offer_position_detail?.currency?.code}
              </div>
              <div className="salary-offer__content-row__field">
                <Input
                  readOnly={readOnly}
                  type="text"
                  name="current_unvested_equity"
                  onChange={handleChange}
                  value={formatNumberWithCommas(offer_lti?.current_unvested_equity || "")}
                  placeholder="Value here..."
                  size="large"
                />
              </div>
              <div className="salary-offer__content-row__field">
                <Input
                  readOnly={readOnly}
                  type="text"
                  name="current_annual_grant"
                  value={formatNumberWithCommas(offer_lti?.current_annual_grant || "")}
                  onChange={handleChange}
                  placeholder="Value here..."
                  size="large"
                />
              </div>

              {offer_lti?.other_ltis
                ?.filter(({ is_current }) => is_current)
                ?.map(({ name, value, id }) => (
                  <div key={id} className="salary-offer__content-row__field">
                    <Input
                      readOnly={readOnly}
                      name={name!}
                      type="text"
                      value={formatNumberWithCommas(value || "")}
                      onChange={e =>
                        handleChangeNewField(id!, +e.target.value.replace(/,/g, ''), "value")
                      }
                      placeholder="Value here..."
                      size="large"
                    />
                  </div>
                ))}

              {/* <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <Input
                  readOnly
                  value={offer_lti?.current_total_lti || ""}
                  name="current_total_lti"
                  placeholder="Value here..."
                  size="large"
                />
              </div> */}
            </Col>
            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div className="salary-offer__content-row__field-upper">
                  {currencyCode}
                </div>
                <div className="salary-offer__content-row__field">
                  <Input
                    value={
                      formatNumberWithCommas( round(
                        offer_lti?.current_unvested_equity! * currencyRate!
                      ) || "")
                    }
                    readOnly
                    type="text"
                    placeholder="Value here..."
                    size="large"
                  />
                </div>
                <div className="salary-offer__content-row__field">
                  <Input
                  type='text'
                    value={
                      formatNumberWithCommas(round(offer_lti?.current_annual_grant! * currencyRate!) ||
                      "")
                    }
                    readOnly
                    placeholder="Value here..."
                    size="large"
                  />
                </div>

                {offer_lti?.other_ltis
                  ?.filter(({ is_current }) => is_current)
                  ?.map(({ id, value }) => (
                    <div key={id} className="salary-offer__content-row__field">
                      <Input
                        value={formatNumberWithCommas(round(value! * currencyRate!) || "")}
                        readOnly
                        placeholder="Value here..."
                        size="large"
                        type="text"
                      />
                    </div>
                  ))}

                {/* <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                  <Input
                    value={current_currency_total || ""}
                    readOnly
                    placeholder="Value here..."
                    size="large"
                  />
                </div> */}
              </Col>
            ) : null}
          </Row>
        </Col>
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__header-row">
            <Col span={12}>
              <div className="sub-heading">Proposed</div>
            </Col>
          </Row>
          <Row className="salary-offer__content-row">
            {/* Proposed-Cols */}

            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field-upper"></div>
              <div className="salary-offer__content-row__field">
                <label>New Hire Equity</label>
              </div>
              {offer_lti?.other_ltis
                ?.filter(({ is_current, unit }) => !is_current && unit)
                ?.map(({ name, id, readonly, unit }) => (
                  <div>
                    <div key={id} className="salary-offer__content-row__field">
                      {name?.length && !readonly! ? (
                        <label>{name}</label>
                      ) : (
                        <div>
                          <Input
                            readOnly={readOnly}
                            autoFocus
                            onChange={e =>
                              handleChangeNewField(
                                id!,
                                e?.target?.value,
                                "name"
                              )
                            }
                            onKeyDown={e => {
                              if (e.key === "Enter") {
                                removeField(id!);
                              }
                            }}
                            value={name || ""}
                            placeholder="Value here..."
                            size="large"
                          />
                        </div>
                      )}
                      {!readOnly && (
                        <div
                          className="salary-offer__content-row__field__remove"
                          onClick={() => removeBonus(id!)}
                        >
                          <PlusIcon fill="#000" />
                        </div>
                      )}
                    </div>
                  </div>
                ))}

              <div className="salary-offer__content-row__field">
                {!readOnly ? (
                  <div
                    onClick={() => addMoreAdhocLocally(false)}
                    className="salary-offer__content-row__field--add-more"
                  >
                    <PlusIcon fill="#4FAA89" />
                    <span>Add more</span>
                  </div>
                ) : (
                  <div className="salary-offer__content-row__field--add-more" />
                )}
              </div>

              <div className="salary-offer__content-row__field">
                <label>Annual Grant</label>
              </div>

              {offer_lti?.other_ltis
                ?.filter(({ is_current, unit }) => !is_current && !unit)
                ?.map(({ name, id, readonly }) => (
                  <div key={id} className="salary-offer__content-row__field">
                    {name?.length && !readonly! ? (
                      <label>{name}</label>
                    ) : (
                      <Input
                        readOnly={readOnly}
                        autoFocus
                        onChange={e =>
                          handleChangeNewField(id!, e?.target?.value, "name")
                        }
                        onKeyDown={e => {
                          if (e.key === "Enter") {
                            removeField(id!);
                          }
                        }}
                        value={name || ""}
                        placeholder="Value here..."
                        size="large"
                      />
                    )}
                    {!readOnly && (
                      <div
                        className="salary-offer__content-row__field__remove"
                        onClick={() => removeBonus(id!)}
                      >
                        <PlusIcon fill="#000" />
                      </div>
                    )}
                  </div>
                ))}

              {!readOnly ? (
                <div
                  onClick={() => addMoreLocally(false)}
                  className="salary-offer__content-row__field--add-more"
                >
                  <PlusIcon fill="#4FAA89" />
                  <span>Add more</span>
                </div>
              ) : (
                <div className="salary-offer__content-row__field--add-more" />
              )}
            </Col>
            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div className="salary-offer__content-row__field-upper">
                {offer?.offer_position_detail?.currency?.code}
              </div>
              <div className="salary-offer__content-row__field">
                <Input
                  readOnly={readOnly}
                  type="text"
                  name="proposed_new_hire_equity"
                  onChange={handleChange}
                  value={formatNumberWithCommas (offer_lti?.proposed_new_hire_equity || "")}
                  placeholder="Value here..."
                  size="large"
                />
              </div>

              <div className="mt-2">
                {offer_lti?.other_ltis
                  ?.filter(({ is_current, unit }) => !is_current && unit)
                  ?.map(({ name, value, id, unit }) => (
                    <div key={id} className="salary-offer__content-row__field ">
                      <div
                        className={`${
                          unit
                            ? "salary-offer__content-row__field__no-unit"
                            : "salary-offer__content-row__field__no-unit"
                        }`}
                      >
                        <Input
                          readOnly={readOnly}
                          name={name!}
                          type="text"
                          value={formatNumberWithCommas(value || "")}
                          onChange={e =>
                            handleChangeNewField(id!, +e.target.value.replace(/,/g, ''), "value")
                          }
                          placeholder="Value here..."
                          size="large"
                        />

                        {unit ? (
                          <div className="salary-offer__content-row__field__no_unit">
                            units: {round(value / (stock || 1))}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ))}
              </div>

              <div className="salary-offer__content-row__field"></div>
              <div className="-mt-2">
                <div className="salary-offer__content-row__field">
                  <Input
                    readOnly={readOnly}
                    type="text"
                    name="proposed_annual_grant"
                    onChange={handleChange}
                    value={formatNumberWithCommas(offer_lti?.proposed_annual_grant || "")}
                    placeholder="Value here..."
                    size="large"
                  />
                </div>
              </div>

              <div className="mt-3">
                {offer_lti?.other_ltis
                  ?.filter(({ is_current, unit }) => !is_current && !unit)
                  ?.map(({ name, value, id, unit }) => (
                    <div key={id} className="salary-offer__content-row__field">
                      <div
                        className={`${
                          !unit
                            ? "salary-offer__content-row__field__no-unit "
                            : "salary-offer__content-row__field__inner"
                        }`}
                      >
                        <Input
                          readOnly={readOnly}
                          name={name!}
                          type="text"
                          value={formatNumberWithCommas(value || "")}
                          onChange={e =>
                            handleChangeNewField(id!, +e.target.value.replace(/,/g, ''), "value")
                          }
                          placeholder="Value here..."
                          size="large"
                        />
                        {!unit ? (
                          <div className="salary-offer__content-row__field__no-unit">
                            amount: {round(value * (stock || 1))}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ))}
              </div>

              {/* <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <Input
                  readOnly
                  name="proposed_total_lti"
                  placeholder="Value here..."
                  value={offer_lti?.proposed_total_lti || ""}
                  size="large"
                />
              </div> */}
              {/* <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <Input
                  readOnly
                  value={
                    offer_lti?.proposed_total_lti &&
                    offer_lti?.current_total_lti
                      ? `${round(
                          (offer_lti?.proposed_total_lti /
                            offer_lti?.current_total_lti -
                            1) *
                            100
                        )}%` || ""
                      : ""
                  }
                  placeholder="Value here..."
                  size="large"
                />
              </div> */}
              {/* <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <Input
                  readOnly
                  name="total_direct_comp"
                  placeholder="Value here..."
                  value={
                    offer_fixed_cash?.proposed_total &&
                    offer_sti?.proposed_total_bonus &&
                    offer_lti?.proposed_total_lti
                      ? offer_fixed_cash?.proposed_total +
                        offer_sti?.proposed_total_bonus +
                        offer_lti?.proposed_total_lti
                      : ""
                  }
                  size="large"
                />
              </div> */}
            </Col>
            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div className="salary-offer__content-row__field-upper">
                  {currencyCode}
                </div>
                <div className="salary-offer__content-row__field">
                  <Input
                    value={
                      formatNumberWithCommas(

                        round(
                          offer_lti?.proposed_new_hire_equity! * currencyRate!
                        ) || ""

                      )
                    }
                    readOnly
                    type="text"
                    placeholder="Value here..."
                    size="large"
                  />
                </div>

                {offer_lti?.other_ltis
                  ?.filter(({ is_current, unit }) => !is_current && unit)
                  ?.map(({ id, value }) => (
                    <div key={id} className="salary-offer__content-row__field">
                      <Input
                        value={ formatNumberWithCommas (round(value! * currencyRate!) || "")}
                        readOnly
                        type="text"
                        placeholder="Value here..."
                        size="large"
                      />
                    </div>
                  ))}
                <div className="salary-offer__content-row__field"></div>

                <div className="salary-offer__content-row__field">
                  <Input
                    value={

                      formatNumberWithCommas(
                      round(
                        offer_lti?.proposed_annual_grant! * currencyRate!
                      ) || ""
                    )
                    }
                    type="text"
                    readOnly
                    placeholder="Value here..."
                    size="large"
                  />
                </div>

                {offer_lti?.other_ltis
                  ?.filter(({ is_current, unit }) => !is_current && !unit)
                  ?.map(({ id, value }) => (
                    <div key={id} className="salary-offer__content-row__field">
                      <Input
                        value={
                      formatNumberWithCommas(
                          
                          round(value! * currencyRate!) || "")}
                        readOnly
                        placeholder="Value here..."
                        size="large"
                        type="text"
                      />
                    </div>
                  ))}
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>

      <Row className="salary-offer">
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <label>Total LTIs</label>
              </div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <Input
                  readOnly
                  value={
                    formatNumberWithCommas(
                    offer_lti?.current_total_lti || "")}
                  name="current_total_lti"
                  placeholder="Value here..."
                  size="large"
                  type="text"
                />
              </div>
            </Col>

            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                  <Input
                    value={formatNumberWithCommas (current_currency_total || "")}
                    readOnly
                    placeholder="Value here..."
                    size="large"
                    type="text"
                  />
                </div>
              </Col>
            ) : null}
          </Row>
        </Col>

        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <label>Total LTIs</label>
              </div>
            </Col>
            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <Input
                  readOnly
                  name="proposed_total_lti"
                  placeholder="Value here..."
                  value={formatNumberWithCommas(  getLtiTotal() || "")}
                  size="large"
                />
              </div>
            </Col>

            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                  <Input
                    value={formatNumberWithCommas (proposed_currency_total || "")}
                    readOnly
                    placeholder="Value here..."
                    size="large"
                    type="text"
                  />
                </div>
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>

      <Row className="salary-offer">
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total"></div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyCode ? 14 : currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total"></div>
            </Col>
          </Row>
        </Col>

        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <label>Total LTIs Difference</label>
              </div>
            </Col>
            <Col
              className="salary-offer__content-row__col"
              span={currencyCode ? 14 : currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <div
                  className={`w-full px-6 text-white shadow-md my-2 font-semibold text-center text-sm outline-none border border-themeInputBorder bg-themeBlue py-2 rounded-md`}
                >
                  {offer_lti?.proposed_total_lti && offer_lti?.current_total_lti
                    ? `${round(
                        (offer_lti?.proposed_total_lti /
                          offer_lti?.current_total_lti -
                          1) *
                          100
                      )}%` || "0%"
                    : "0%"}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="salary-offer">
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <label>Total Direct Comp</label>
              </div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <Input
                  readOnly
                  name="total_direct_comp"
                  placeholder="Value here..."
                  value={formatNumberWithCommas (currentTotal || "")}
                  size="large"
                  type="text"
                />
              </div>
            </Col>

            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                  <Input
                    value={formatNumberWithCommas(round(currentTotal * currencyRate!) || "")}
                    readOnly
                    placeholder="Value here..."
                    size="large"
                    type="text"
                  />
                </div>
              </Col>
            ) : null}
          </Row>
        </Col>

        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <label>Total Direct Comp</label>
              </div>
            </Col>
            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <Input
                  readOnly
                  type="text"
                  name="total_direct_comp"
                  placeholder="Value here..."
                  value={formatNumberWithCommas(round(proposedTotal) || "")}
                  size="large"
                />
              </div>
            </Col>

            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                  <Input
                  type="text"
                    value={formatNumberWithCommas (round(proposedTotal * currencyRate!) || "")}
                    readOnly
                    placeholder="Value here..."
                    size="large"
                  />
                </div>
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>

      <Row className="salary-offer">
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total"></div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyCode ? 14 : currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total"></div>
            </Col>
          </Row>
        </Col>

        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <label>Direct Comp Difference</label>
              </div>
            </Col>
            <Col
              className="salary-offer__content-row__col"
              span={currencyCode ? 14 : currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <div
                  className={`w-full px-6 text-white shadow-md my-2 font-semibold text-center text-sm outline-none border border-themeInputBorder bg-themeBlue py-2 rounded-md`}
                >
                  {finalPercentage}%
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default LongTermIncentives;
