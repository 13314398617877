const admin = {
  auth: {
    login: "/login",
    forgot_password: "/forgot-password",
    reset_password: "/reset-password",
  },
  dashboard: {
    dashboard: "/",
  },

  users: {
    companies: {
      listing: "/companies",
      create: "/companies/create",
      edit: "/companies/:company_id/edit",
      profile: {
        details: "/companies/:company_id",
        business_units: {
          listing: "/companies/:company_id/business-units",
          regions: "/companies/:company_id/business-units/:business_unit_id",
          countries:
            "/companies/:company_id/business-units/:business_unit_id/:region_id",
          legal_entities:
            "/companies/:company_id/business-units/:business_unit_id/:region_id/:country_id",
        },

        regions: "/companies/:company_id/regions",
      },
    },
    sub_admins: {
      listing: "/sub-admins",
      create: "/sub-admins/create",
      edit: "/sub-admins/edit/:sub_admin_id",
    },
  },

  settings: {
    sectors: {
      listing: "/sectors",
      industry: "/sectors/:sector_id",
      sub_industry: "/sectors/:sector_id/:industry_id",
    },
    job_function: {
      listing: "/job-functions",
      sub_job_function: "/job-functions/:job_id",
    },
    grade_map_table: {
      listing: "/grade-map-table",
      create_grade_company: "/grade-map-table/create-grade-company",
      edit_grade_company: "/grade-map-table/edit-grade-company",
    },
  },
};

const client = {
  dashboard: "/client",
  auth: {
    login: "/client/login",
    forgot_password: "/client/forgot-password",
    reset_password: "/client/reset-password",
  },
  companies: {
    edit: "/client/companies/:company_id/edit",
    profile: {
      details: "/client/companies/:company_id",
      business_units: {
        listing: "/client/companies/:company_id/business-units",
        regions:
          "/client/companies/:company_id/business-units/:business_unit_id",
        countries:
          "/client/companies/:company_id/business-units/:business_unit_id/:region_id",
        legal_entities:
          "/client/companies/:company_id/business-units/:business_unit_id/:region_id/:country_id",
      },
      regions: "/client/companies/:company_id/regions",
    },
  },
  sub_admins: {
    listing: "/client/sub-admins",
    create: "/client/sub-admins/create",
    edit: "/client/sub-admins/edit/:sub_admin_id",
  },

  grade_setup: "/client/grade-setup",
  salary_range: "/client/salary-range",
  cash_allowances: "/client/cash-allowances",
  short_term_ip: {
    plans: "/client/short-term-plans",
    plan: "/client/short-term-plans/:plan_id",
    incentives: "/client/short-term-plans/incentives",
  },
  long_term_ip: {
    plans: "/client/long-term-plans",
    plan: "/client/long-term-plans/:plan_id",
    incentives: "/client/long-term-plans/incentives",
  },
  internal_payroll_data: "/client/internal-payroll-data",
  market_data: "/client/market-data",
  // offers1: "/client/offers",
  offers: {
    listings: "/client/offers",
    offer: "/client/offers/offer/:offer_id",
    active: "/client/offers/active",
    past: "/client/offers/past",
    drafts: "/client/offers/drafts",
    create: "/client/offers/create",
  },
};

export { admin, client };
