import { Col, Input, Row } from "antd";
import { FC } from "react";

import { IModal } from "@types";
import Modal from "@components/Modal";
import { useTypedSelector } from "@hooks";
import { useFetchComparatorDataQuery } from "@services";

const ExternalComparatorData: FC<IModal> = ({ isVisible, setIsVisible }) => {
  const { offer } = useTypedSelector(state => state?.offers);
  const { company } = useTypedSelector(state => state.auth?.user!);
  const { id: company_id } = company!;
  const { data } = useFetchComparatorDataQuery(
    {
      company_id,
      offer_id: offer?.id,
    },
    { skip: !offer?.id }
  );
  const { data: comparatorData } = data || {};
  const { external_comparator_data } = comparatorData || {};
  const { market_data_grade, market_data_grade_function } =
    external_comparator_data || {};


    const formatNumberWithCommas = (number: any): string | null => {
      // Check if the number is null, undefined, or NaN, or if it's an empty string
      if (number == null || isNaN(Number(number)) || number === "") return null;
  
      // Convert input to a number if it's a string
      const numericValue = Number(number);
  
      // If the number is zero, return "0" explicitly
      if (numericValue === 0) return "";
  
      // Return the formatted number with commas using toLocaleString
      return numericValue.toLocaleString();
    };

  return (
    <Modal
      mode="versions"
      footer={null}
      title="External Comparator Data"
      isVisible={isVisible}
      width={855}
      setIsVisible={setIsVisible}
    >
      <div className="px-10 py-10">
      <div className="offers__modal__row">
          <div className="offers__modal__title">{`Market Data - Grade ${offer?.offer_position_detail?.grade}`}</div>
          <Row justify="space-between mb-10">
            <Col span={7}>
              <label>P25</label>
              <Input
                readOnly
                value={formatNumberWithCommas(market_data_grade?.p25 || "")}
                placeholder="P25 value"
                size="large"
              />
            </Col>
            <Col span={7}>
              <label>P50</label>
              <Input
                readOnly
                value={formatNumberWithCommas(market_data_grade?.p50 || "")}
                placeholder="P50 value"
                size="large"
              />
            </Col>
            <Col span={7}>
              <label>P75</label>
              <Input
                readOnly
                value={formatNumberWithCommas(market_data_grade?.p75 || "")}
                placeholder="P75 value"
                size="large"
              />
            </Col>
          </Row>
        </div>
        <div className="offers__modal__row">
          <div className="offers__modal__title">
            {`Market Data - Grade ${offer?.offer_position_detail?.grade} - Functional`}
          </div>
          <Row justify="space-between">
            <Col span={7}>
              <label>P25</label>
              <Input
                readOnly
                value={formatNumberWithCommas(market_data_grade_function?.p25 || "")}
                placeholder="P25 value"
                size="large"
              />
            </Col>
            <Col span={7}>
              <label>P50</label>
              <Input
                readOnly
                value={formatNumberWithCommas(market_data_grade_function?.p50 || "")}
                placeholder="P50 value"
                size="large"
              />
            </Col>
            <Col span={7}>
              <label>P75</label>
              <Input
                readOnly
                value={formatNumberWithCommas(market_data_grade_function?.p75 || "")}
                placeholder="P75 value"
                size="large"
              />
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
};

export default ExternalComparatorData;
