import { Row, Col, Input } from "antd";
import _ from "lodash";
import { useDispatch } from "react-redux";

import { ReactComponent as PlusIcon } from "@assets/images/plus-with-bg.svg";
import { useTypedSelector } from "@hooks";
import { handleOfferModeller, removeCurrency } from "@store/offers";
import { getCurrencyTotal, getTotal, round } from "@utils";
import {
  offersApi,
  useFetchAutoPopulateDataQuery,
  useFetchComparatorDataQuery,
  useFetchOfferCurrencyRateQuery,
} from "@services";
import { useEffect } from "react";

const FixedCash = () => {
  const dispatch = useDispatch();
  const {
    offerModeller: {
      offer_sti: { is_sale_incentive },
    },
  } = useTypedSelector(state => state?.offers);
  const {
    offerModeller: { offer_fixed_cash, offer_sti },
    currencyRate,
    currencyName,
    offer,
    offerCurrency,
    offer_annual,
  } = useTypedSelector(state => state.offers);
  const currencyCode = currencyName?.split("(")[1]?.replace(")", "") || "";
  const { company } = useTypedSelector(state => state.auth?.user!);
  const { id: company_id } = company!;
  const { data } = useFetchComparatorDataQuery(
    {
      company_id,
      offer_id: offer?.id,
    },
    { skip: !offer?.id }
  );
  const { data: comparatorData } = data || {};
  const readOnly = offer && offer?.status !== "DRAFTED" ? true : false;
  const labelColSpan = currencyCode ? 10 : 12;
  const currencyColSpan = currencyCode ? 7 : 12;

  const { current_currency_total, proposed_currency_total } = getCurrencyTotal(
    offer_fixed_cash,
    currencyRate,
    [
      "current_compa_ratio",
      "current_market_ratio",
      "proposed_compa_ratio",
      "proposed_market_ratio",
    ]
  );

  const handleChange = (event: any) => {
    const cleanedValue = event.target.value.replace(/,/g, "");

    dispatch(
      handleOfferModeller({
        key: "offer_fixed_cash",
        data: {
          [event.target.name]: +cleanedValue || null,
        },
      })
    );
  };

  const addMoreLocally = (is_current: boolean) => {
    const newCurrent = {
      is_current,
      value: null,
      name: null,
      id: +_.uniqueId(),
      readonly: true,
    };
    dispatch(
      handleOfferModeller({
        key: "offer_fixed_cash",
        data: {
          allowances: [...offer_fixed_cash.allowances, newCurrent],
        },
      })
    );
  };

  const handleChangeNewField = (
    id: number,
    value: number | string,
    type: "name" | "value"
  ) => {
    dispatch(
      handleOfferModeller({
        key: "offer_fixed_cash",
        data: {
          allowances: offer_fixed_cash?.allowances?.map(allowance =>
            allowance?.id === id ? { ...allowance, [type]: value } : allowance
          ) as any,
        },
      })
    );
  };

  const removeField = (id: number) => {
    dispatch(
      handleOfferModeller({
        key: "offer_fixed_cash",
        data: {
          allowances: offer_fixed_cash?.allowances?.map(allowance =>
            allowance?.id === id ? { ...allowance, readonly: false } : allowance
          ) as any,
        },
      })
    );
  };

  const removeAllowance = (id: number) => {
    dispatch(
      handleOfferModeller({
        key: "offer_fixed_cash",
        data: {
          allowances: offer_fixed_cash?.allowances?.filter(
            allowance => allowance?.id !== id
          ) as any,
        },
      })
    );
  };

  let id = comparatorData?.currency?.market_currency?.id;
  const currencyId = typeof id === "string" ? Number(id) : id;

  useFetchOfferCurrencyRateQuery(
    {
      code: currencyId,
      company_id,
      offer_id: offer?.id,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !comparatorData?.currency?.market_currency?.id,
    }
  );

  useFetchAutoPopulateDataQuery(
    {
      company_id,
      offer_id: offer?.id,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !offerCurrency,
    }
  );

  useEffect(() => {
    // if (id && id != offer?.offer_position_detail?.currency?.id) {
    //   dispatch(
    //     offersApi.endpoints.fetchOfferCurrencyRate.initiate(
    //       {
    //         code: id,
    //         company_id,
    //         offer_id: offer?.id,
    //       },
    //       {
    //         subscribe: false,
    //         forceRefetch: true,
    //       }
    //     )
    //   );

    //   // updateNew();
    // }

    dispatch(removeCurrency());
  }, [id]);

  // let updateNew = () => {

  //   if(offer_fixed_cash?.allowances.length >0){
  //     let val = offer_fixed_cash?.proposed_annual_base;

  //     dispatch(
  //       handleOfferModeller({
  //         key: "offer_fixed_cash",
  //         data: {
  //           proposed_annual_base: val || null,
  //           proposed_compa_ratio:
  //             (comparatorData?.internal_comparator_data?.internal_salary_range
  //               ?.mid &&
  //               val &&
  //               (offerCurrency != null ? val * offerCurrency : val) /
  //                 comparatorData?.internal_comparator_data?.internal_salary_range
  //                   ?.mid) ||
  //             null,
  //           proposed_market_ratio:
  //             (comparatorData?.external_comparator_data?.market_data_grade?.p50 &&
  //               val &&
  //               val /
  //                 comparatorData?.external_comparator_data?.market_data_grade
  //                   ?.p50) ||
  //             null,
  //         },
  //       })
  //     );

  //   }

  // };

  // useEffect(() => {
  //   updateNew();
  // }, [offerCurrency]);

  const formatNumberWithCommas = (number: any): string | null => {
    // Check if the number is null, undefined, or NaN, or if it's an empty string
    if (number == null || isNaN(Number(number)) || number === "") return null;

    // Convert input to a number if it's a string
    const numericValue = Number(number);

    // If the number is zero, return "0" explicitly
    if (numericValue === 0) return "";

    // Return the formatted number with commas using toLocaleString
    return numericValue.toLocaleString();
  };

  return (
    <>
      <Row className="salary-offer">
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__header-row cursor-pointer border-b border-themeBorder bg-themeCore">
            <Col span={12}>
              <div className="sub-heading font-semibold text-lg">Current</div>
            </Col>
          </Row>

          <Row className="salary-offer__content-row">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field-upper"></div>

              <div
                className={` ${
                  offer_annual
                    ? "salary-offer__content-row__field"
                    : "salary-offer__content-row__field_extra"
                } `}
              >
                <label>Annual Base</label>
              </div>
              <div className="salary-offer__content-row__field">
                <label>Compa Ratio</label>
              </div>
              <div className="salary-offer__content-row__field">
                <label>Market Ratio</label>
              </div>

              {offer_fixed_cash?.allowances
                ?.filter(({ is_current }) => is_current)
                ?.map(allowance => {
                  const { name, id } = allowance;
                  return (
                    <div
                      key={id}
                      className={` ${
                        offer_annual
                          ? "salary-offer__content-row__field"
                          : "salary-offer__content-row__field_extra"
                      } `}
                    >
                      {name?.length && !allowance?.readonly! ? (
                        <label>{name}</label>
                      ) : (
                        <Input
                          className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                          readOnly={readOnly}
                          autoFocus
                          onChange={e =>
                            handleChangeNewField(id!, e?.target?.value, "name")
                          }
                          onKeyDown={e => {
                            if (e.key === "Enter") {
                              removeField(id!);
                            }
                          }}
                          value={name || ""}
                          placeholder="Value here..."
                          size="large"
                        />
                      )}
                      {!readOnly && (
                        <div
                          className="salary-offer__content-row__field__remove"
                          onClick={() => removeAllowance(id!)}
                        >
                          <PlusIcon fill="#000" />
                        </div>
                      )}
                    </div>
                  );
                })}

              {!readOnly ? (
                <div
                  onClick={() => addMoreLocally(true)}
                  className="salary-offer__content-row__field--add-more"
                >
                  <PlusIcon fill="#4FAA89" />
                  <span>Add more</span>
                </div>
              ) : (
                <div className="salary-offer__content-row__field--add-more" />
              )}
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div className="salary-offer__content-row__field-upper">
                {offer?.offer_position_detail?.currency?.code}
              </div>
              <div
                className={` ${
                  offer_annual
                    ? "salary-offer__content-row__field"
                    : "salary-offer__content-row__field_extra"
                } `}
              >
                <div className="w-full">
                  <Input
                    className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                    readOnly={readOnly}
                    type="text"
                    name="current_annual_base"
                    onChange={handleChange}
                    value={formatNumberWithCommas(
                      offer_fixed_cash?.current_annual_base || ""
                    )}
                    placeholder="Value here..."
                    size="large"
                  />

                  {!offer_annual && (
                    <p className="pl-1">
                      Monthly:{" "}
                      {formatNumberWithCommas(
                        round(
                          offer_fixed_cash?.current_annual_base
                            ? offer_fixed_cash?.current_annual_base / 12
                            : 0
                        )
                      )}
                    </p>
                  )}
                </div>
              </div>
              <div className="salary-offer__content-row__field">
                <Input
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  readOnly={readOnly}
                  type="text"
                  name="current_compa_ratio"
                  value={formatNumberWithCommas(
                    offer_fixed_cash?.current_compa_ratio || ""
                  )}
                  onChange={handleChange}
                  placeholder="Value here..."
                  size="large"
                />
              </div>
              <div className="salary-offer__content-row__field">
                <Input
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  readOnly={readOnly}
                  type="text"
                  name="current_market_ratio"
                  value={formatNumberWithCommas(
                    offer_fixed_cash?.current_market_ratio || ""
                  )}
                  onChange={handleChange}
                  placeholder="Value here..."
                  size="large"
                />
              </div>

              {offer_fixed_cash?.allowances
                ?.filter(({ is_current }) => is_current)
                ?.map(({ name, value, id }) => (
                  <div
                    key={id}
                    className={` ${
                      offer_annual
                        ? "salary-offer__content-row__field"
                        : "salary-offer__content-row__field_extra"
                    } `}
                  >
                    <div className="w-full">
                      <Input
                        className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                        readOnly={readOnly}
                        name={name!}
                        type="text"
                        value={formatNumberWithCommas(value)}
                        onChange={e =>
                          handleChangeNewField(
                            id!,
                            +e.target.value.replace(/,/g, ""),
                            "value"
                          )
                        }
                        placeholder="Value here..."
                        size="large"
                      />

                      {!offer_annual && (
                        <p className="pl-1">
                          Monthly:{" "}
                          {formatNumberWithCommas(
                            round(value ? value / 12 : 0)
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                ))}

              <div className="salary-offer__content-row__field--add-more" />
            </Col>
            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div className="salary-offer__content-row__field-upper">
                  {currencyCode}
                </div>
                <div
                  className={` ${
                    offer_annual
                      ? "salary-offer__content-row__field"
                      : "salary-offer__content-row__field_extra"
                  } `}
                >
                  <div>
                    <Input
                      type="text"
                      className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                      value={formatNumberWithCommas(
                        round(
                          offer_fixed_cash?.current_annual_base! * currencyRate!
                        ) || ""
                      )}
                      readOnly
                      placeholder="Value here..."
                      size="large"
                    />

                    {!offer_annual && (
                      <p className="pl-1">
                        Monthly:{" "}
                        {formatNumberWithCommas (round(
                         offer_fixed_cash?.current_annual_base! *
                         currencyRate!
                            ? offer_fixed_cash?.current_annual_base! *
                            currencyRate! / 12
                            : 0
                        ))}
                      </p>
                    )}
                  </div>
                </div>
                <div className="salary-offer__content-row__field" />
                <div className="salary-offer__content-row__field" />
                {offer_fixed_cash?.allowances
                  ?.filter(({ is_current }) => is_current)
                  ?.map(({ id, value }) => (
                    <div
                      key={id}
                      className={` ${
                        offer_annual
                          ? "salary-offer__content-row__field"
                          : "salary-offer__content-row__field_extra"
                      } `}
                    >
                      <div>
                        <Input
                          type="text"
                          className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                          value={formatNumberWithCommas(
                            round(value! * currencyRate!) || ""
                          )}
                          readOnly
                          placeholder="Value here..."
                          size="large"
                        />

                        {!offer_annual && (
                          <p className="pl-1">
                            Monthly:{" "}
                            {formatNumberWithCommas(
                              round(
                                round(value! * currencyRate!)
                                  ? round(value! * currencyRate!) / 12
                                  : 0
                              )
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
              </Col>
            ) : null}
          </Row>
        </Col>

        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__header-row cursor-pointer border-b border-themeBorder bg-themeCore">
            <Col span={12}>
              <div className="sub-heading font-semibold text-lg">Proposed</div>
            </Col>
          </Row>
          <Row className="salary-offer__content-row">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field-upper"></div>

              <div
                className={` ${
                  offer_annual
                    ? "salary-offer__content-row__field"
                    : "salary-offer__content-row__field_extra"
                } `}
              >
                <label>Annual Base</label>
              </div>
              <div className="salary-offer__content-row__field">
                <label>Compa Ratio</label>
              </div>
              <div className="salary-offer__content-row__field">
                <label>Market Ratio</label>
              </div>

              {offer_fixed_cash?.allowances
                ?.filter(({ is_current }) => !is_current)
                ?.map(({ name, id, readonly }) => (
                  <div
                    key={id}
                    className={` ${
                      offer_annual
                        ? "salary-offer__content-row__field"
                        : "salary-offer__content-row__field_extra"
                    } `}
                  >
                    {name?.length && !readonly! ? (
                      <label>{name}</label>
                    ) : (
                      <Input
                        className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                        readOnly={readOnly}
                        autoFocus
                        onChange={e =>
                          handleChangeNewField(id!, e?.target?.value, "name")
                        }
                        onKeyDown={e => {
                          if (e.key === "Enter") {
                            removeField(id!);
                          }
                        }}
                        value={name || ""}
                        placeholder="Value here..."
                        size="large"
                      />
                    )}
                    {!readOnly && (
                      <div
                        className="salary-offer__content-row__field__remove"
                        onClick={() => removeAllowance(id!)}
                      >
                        <PlusIcon fill="#000" />
                      </div>
                    )}
                  </div>
                ))}
              {!readOnly ? (
                <div
                  onClick={() => addMoreLocally(false)}
                  className="salary-offer__content-row__field--add-more"
                >
                  <PlusIcon fill="#4FAA89" />
                  <span>Add more</span>
                </div>
              ) : (
                <div className="salary-offer__content-row__field--add-more" />
              )}
            </Col>
            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div className="salary-offer__content-row__field-upper">
                {offer?.offer_position_detail?.currency?.code}
              </div>

              <div
                className={` ${
                  offer_annual
                    ? "salary-offer__content-row__field"
                    : "salary-offer__content-row__field_extra"
                } `}
              >
                <div className="w-full">
                  <Input
                    className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                    disabled={is_sale_incentive}
                    readOnly={readOnly}
                    type="text"
                    name="proposed_annual_base"
                    onChange={event => {
                      const val = +event.target.value.replace(/,/g, "");
                      dispatch(
                        handleOfferModeller({
                          key: "offer_fixed_cash",
                          data: {
                            proposed_annual_base: val || null,
                            proposed_compa_ratio:
                              (comparatorData?.internal_comparator_data
                                ?.internal_salary_range?.mid &&
                                val &&
                                (offerCurrency != null
                                  ? val * offerCurrency
                                  : val) /
                                  comparatorData?.internal_comparator_data
                                    ?.internal_salary_range?.mid) ||
                              null,

                            proposed_market_ratio:
                              comparatorData?.external_comparator_data
                                ?.market_data_grade?.p50 && val
                                ? (offerCurrency != null
                                    ? val * offerCurrency
                                    : val) /
                                  (comparatorData?.external_comparator_data
                                    ?.market_data_grade_function?.p50 ||
                                    comparatorData?.external_comparator_data
                                      ?.market_data_grade?.p50)
                                : null,
                          },
                        })
                      );

                      dispatch(
                        handleOfferModeller({
                          key: "offer_fixed_cash",
                          data: {
                            allowances: offer_fixed_cash?.allowances?.map(
                              allowance => {
                                return allowance?.is_percentage
                                  ? {
                                      ...allowance,
                                      value: allowance?.percentage
                                        ? (val * allowance?.percentage) / 100
                                        : val,
                                    }
                                  : allowance;
                              }
                            ),
                          },
                        })
                      );

                      dispatch(
                        handleOfferModeller({
                          key: "offer_sti",
                          data: {
                            bonuses: offer_sti?.bonuses?.map(bonus => {
                              return bonus?.is_percentage && bonus?.is_basic_pay
                                ? {
                                    ...bonus,
                                    value: bonus?.percentage
                                      ? (val * bonus?.percentage) / 100
                                      : val,
                                  }
                                : bonus?.is_percentage && !bonus?.is_basic_pay
                                ? {
                                    ...bonus,
                                    value: bonus?.percentage
                                      ? (offer_fixed_cash?.proposed_total *
                                          bonus?.percentage) /
                                        100
                                      : val,
                                  }
                                : bonus;
                            }),
                          },
                        })
                      );
                    }}
                    value={formatNumberWithCommas(
                      is_sale_incentive
                        ? offer_fixed_cash?.proposed_annual_base || ""
                        : offer_fixed_cash?.proposed_annual_base || ""
                    )}
                    placeholder="Value here..."
                    size="large"
                  />

                  {!offer_annual && (
                    <p className="pl-1">
                      Monthly:{" "}
                      {formatNumberWithCommas(
                        round(
                          offer_fixed_cash?.proposed_annual_base
                            ? offer_fixed_cash?.proposed_annual_base / 12
                            : 0
                        )
                      )}
                    </p>
                  )}
                </div>
              </div>
              <div className="salary-offer__content-row__field">
                <Input
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  readOnly
                  type="text"
                  name="proposed_compa_ratio"
                  value={formatNumberWithCommas(
                    round(offer_fixed_cash?.proposed_compa_ratio) || ""
                  )}
                  placeholder="Value here..."
                  size="large"
                />
              </div>
              <div className="salary-offer__content-row__field">
                <Input
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  readOnly
                  type="text"
                  name="proposed_market_ratio"
                  value={formatNumberWithCommas(
                    round(offer_fixed_cash?.proposed_market_ratio) || ""
                  )}
                  placeholder="Value here..."
                  size="large"
                />
              </div>

              {offer_fixed_cash?.allowances
                ?.filter(({ is_current }) => !is_current)
                ?.map(({ name, value, id, is_percentage }) => (
                  <div
                    key={id}
                    className={` ${
                      offer_annual
                        ? "salary-offer__content-row__field"
                        : "salary-offer__content-row__field_extra"
                    } `}
                  >
                    <div className="w-full">
                      <Input
                        className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                        readOnly={readOnly}
                        name={name!}
                        contentEditable={false}
                        type="text"
                        value={formatNumberWithCommas(
                          is_percentage && offer_fixed_cash.proposed_annual_base
                            ? value
                            : !is_percentage
                            ? value || ""
                            : ""
                        )}
                        onChange={e => {
                          if (!is_percentage) {
                            handleChangeNewField(
                              id!,
                              +e.target.value.replace(/,/g, ""),
                              "value"
                            );
                          }
                        }}
                        size="large"
                      />

                      {!offer_annual && (
                        <p className="pl-1">
                          Monthly:{" "}
                          {formatNumberWithCommas(
                            round(
                              is_percentage &&
                                offer_fixed_cash.proposed_annual_base
                                ? value / 12
                                : !is_percentage
                                ? (value || 0) / 12
                                : 0
                            )
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                ))}

              <div className="salary-offer__content-row__field--add-more" />
            </Col>
            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div className="salary-offer__content-row__field-upper">
                  {currencyCode}
                </div>
                <div
                  className={` ${
                    offer_annual
                      ? "salary-offer__content-row__field"
                      : "salary-offer__content-row__field_extra"
                  } `}
                >
                  <div className="w-full">
                    <Input
                      type="text"
                      className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                      value={formatNumberWithCommas(
                        round(
                          offer_fixed_cash?.proposed_annual_base! *
                            currencyRate!
                        ) || ""
                      )}
                      readOnly
                      placeholder="Value here..."
                      size="large"
                    />

                    {!offer_annual && (
                      <p className="pl-1">
                        Monthly:{" "}
                        {formatNumberWithCommas(
                          round(
                            offer_fixed_cash?.proposed_annual_base! *
                              currencyRate!
                              ? (offer_fixed_cash?.proposed_annual_base! *
                                  currencyRate!) /
                                  12
                              : 0
                          )
                        )}
                      </p>
                    )}
                  </div>
                </div>
                <div className="salary-offer__content-row__field" />
                <div className="salary-offer__content-row__field" />
                {offer_fixed_cash?.allowances
                  ?.filter(({ is_current }) => !is_current)
                  ?.map(({ id, value }) => (
                    <div
                      key={id}
                      className={` ${
                        offer_annual
                          ? "salary-offer__content-row__field"
                          : "salary-offer__content-row__field_extra"
                      } `}
                    >
                      <div className="w-full">
                        <Input
                          type="text"
                          className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                          value={formatNumberWithCommas(
                            round(value! * currencyRate!) || ""
                          )}
                          readOnly
                          placeholder="Value here..."
                          size="large"
                        />

                        {!offer_annual && (
                          <p className="pl-1">
                            Monthly:{" "}
                            {formatNumberWithCommas(
                              round(
                                round(value! * currencyRate!)
                                  ? round(value! * currencyRate!) / 12
                                  : 0
                              )
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                  ))}

                <div className="salary-offer__content-row__field--add-more" />
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>

      <Row className="salary-offer">
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row__col">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div
                className={` ${
                  offer_annual
                    ? "salary-offer__content-row__field  salary-offer__content-row__field--total"
                    : "salary-offer__content-row__field_extra  salary-offer__content-row__field--total"
                } `}
              >
                <label>Total Guaranteed Cash</label>
              </div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div
                className={` ${
                  offer_annual
                    ? "salary-offer__content-row__field  salary-offer__content-row__field--total"
                    : "salary-offer__content-row__field_extra  salary-offer__content-row__field--total"
                } `}
              >
                <div className="w-full">
                  <Input
                    className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                    readOnly
                    name="current_total"
                    value={formatNumberWithCommas(
                      offer_fixed_cash?.current_total || ""
                    )}
                    placeholder="Value here..."
                    size="large"
                  />
                  {!offer_annual && (
                    <p className="pl-1">
                      Monthly:{" "}
                      {formatNumberWithCommas(
                        round(
                          offer_fixed_cash?.current_total
                            ? offer_fixed_cash?.current_total / 12
                            : 0
                        )
                      )}
                    </p>
                  )}
                </div>
              </div>
            </Col>

            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div
                  className={` ${
                    offer_annual
                      ? "salary-offer__content-row__field  salary-offer__content-row__field--total"
                      : "salary-offer__content-row__field_extra  salary-offer__content-row__field--total"
                  } `}
                >
                  <div>
                    <Input
                      className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                      value={formatNumberWithCommas(
                        current_currency_total || ""
                      )}
                      readOnly
                      placeholder="Value here..."
                      size="large"
                    />
                    {!offer_annual && (
                      <p className="pl-1">
                        Monthly:{" "}
                        {formatNumberWithCommas(
                          round(
                            current_currency_total
                              ? current_currency_total / 12
                              : 0
                          )
                        )}
                      </p>
                    )}
                  </div>
                </div>
              </Col>
            ) : null}
          </Row>
        </Col>

        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row__col">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div
                className={` ${
                  offer_annual
                    ? "salary-offer__content-row__field  salary-offer__content-row__field--total"
                    : "salary-offer__content-row__field_extra  salary-offer__content-row__field--total"
                } `}
              >
                <label>Total Guaranteed Cash</label>
              </div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div
                className={` ${
                  offer_annual
                    ? "salary-offer__content-row__field  salary-offer__content-row__field--total"
                    : "salary-offer__content-row__field_extra  salary-offer__content-row__field--total"
                } `}
              >
                <div className="w-full">
                  <Input
                    className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                    readOnly
                    name="proposed_total"
                    value={formatNumberWithCommas(
                      offer_fixed_cash?.proposed_total || ""
                    )}
                    placeholder="Value here..."
                    size="large"
                    type="text"
                  />

                  {!offer_annual && (
                    <p className="pl-1">
                      Monthly:{" "}
                      {formatNumberWithCommas(
                        round(
                          offer_fixed_cash?.proposed_total
                            ? offer_fixed_cash?.proposed_total / 12
                            : 0
                        )
                      )}
                    </p>
                  )}
                </div>
              </div>
            </Col>

            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div
                  className={` ${
                    offer_annual
                      ? "salary-offer__content-row__field  salary-offer__content-row__field--total"
                      : "salary-offer__content-row__field_extra  salary-offer__content-row__field--total"
                  } `}
                >
                  <div className="w-full">
                    <Input
                      className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                      value={proposed_currency_total || ""}
                      readOnly
                      placeholder="Value here..."
                      size="large"
                    />

                    {!offer_annual && (
                      <p className="pl-1">
                        Monthly:{" "}
                        {formatNumberWithCommas(
                          round(
                            proposed_currency_total
                              ? proposed_currency_total / 12
                              : 0
                          )
                        )}
                      </p>
                    )}
                  </div>
                </div>
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>

      <Row className="salary-offer">
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row__col">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total"></div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyCode ? 14 : currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total"></div>
            </Col>
          </Row>
        </Col>

        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row__col">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <label>Difference</label>
              </div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyCode ? 14 : currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <div
                  className={`w-full px-6   text-white shadow-md my-2 font-semibold text-center text-sm outline-none border border-themeInputBorder bg-themeBlue  py-2 rounded-md`}
                >
                  {offer_fixed_cash?.proposed_total &&
                  offer_fixed_cash?.current_total
                    ? `${round(
                        (offer_fixed_cash?.proposed_total /
                          offer_fixed_cash?.current_total -
                          1) *
                          100
                      )}%` || ""
                    : "0%"}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default FixedCash;
