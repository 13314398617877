import { useDispatch } from "react-redux";
import { Row, Col, Input } from "antd";
import _ from "lodash";

import { ReactComponent as PlusIcon } from "@assets/images/plus-with-bg.svg";
import { useTypedSelector } from "@hooks";
import { handleOfferModeller } from "@store/offers";
import { getCurrencyTotal, round } from "@utils";

const OfferBenefits = () => {
  const dispatch = useDispatch();

  const {
    offerModeller: { offer_benefits, offer_lti, offer_sti, offer_fixed_cash },
    currencyRate,
    currencyName,
    offer,
    offer_annual,
  } = useTypedSelector(state => state.offers);
  const readOnly = offer && offer?.status !== "DRAFTED" ? true : false;
  const currencyCode = currencyName?.split("(")[1]?.replace(")", "") || "";

  const { current_currency_total, proposed_currency_total } = getCurrencyTotal(
    offer_benefits,
    currencyRate,
    []
  );

  const labelColSpan = currencyCode ? 10 : 12;
  const currencyColSpan = currencyCode ? 7 : 12;

  const addMoreLocally = (is_current: boolean) => {
    const newCurrent = {
      is_current,
      value: null,
      name: null,
      id: +_.uniqueId(),
      readonly: true,
    };
    dispatch(
      handleOfferModeller({
        key: "offer_benefits",
        data: {
          bonuses: [...offer_benefits.bonuses, newCurrent],
        },
      })
    );
  };

  const handleChangeNewField = (
    id: number,
    value: number | string,
    type: "name" | "value"
  ) => {
    dispatch(
      handleOfferModeller({
        key: "offer_benefits",
        data: {
          bonuses: offer_benefits?.bonuses?.map(bonus =>
            bonus?.id === id ? { ...bonus, [type]: value } : bonus
          ) as any,
        },
      })
    );
  };

  const removeField = (id: number) => {
    dispatch(
      handleOfferModeller({
        key: "offer_benefits",
        data: {
          bonuses: offer_benefits?.bonuses?.map(bonus =>
            bonus?.id === id ? { ...bonus, readonly: false } : bonus
          ) as any,
        },
      })
    );
  };

  const removeBonus = (id: number) => {
    dispatch(
      handleOfferModeller({
        key: "offer_benefits",
        data: {
          bonuses: offer_benefits?.bonuses?.filter(
            bonus => bonus?.id !== id
          ) as any,
        },
      })
    );
  };

  const proposedTotal =
    (offer_fixed_cash?.proposed_total || 0) +
    (offer_sti?.proposed_total_bonus || 0) +
    (offer_lti?.proposed_total_lti || 0) +
    (offer_benefits?.proposed_total_bonus || 0);
  const currentTotal =
    (offer_fixed_cash?.current_total || 0) +
    (offer_sti?.current_bonus_target || 0) +
    (offer_lti?.current_total_lti || 0) +
    (offer_benefits?.current_total_bonus || 0);

  let percentageDifference = 0;

  if (proposedTotal > 0 && currentTotal > 0) {
    percentageDifference = (proposedTotal / currentTotal - 1) * 100;
  }

  const finalPercentage =
    percentageDifference > 0 ? percentageDifference.toFixed(0) : 0;

  const formatNumberWithCommas = (number: any): string | null => {
    // Check if the number is null, undefined, or NaN, or if it's an empty string
    if (number == null || isNaN(Number(number)) || number === "") return null;

    // Convert input to a number if it's a string
    const numericValue = Number(number);

    // If the number is zero, return "0" explicitly
    if (numericValue === 0) return "";

    // Return the formatted number with commas using toLocaleString
    return numericValue.toLocaleString();
  };

  return (
    <>
      <Row className="salary-offer">
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__header-row">
            <Col span={12}>
              <div className="sub-heading">Current</div>
            </Col>
          </Row>
          <Row className="salary-offer__content-row salary-offer__content-row--last">
            {/* Current-Cols */}
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field-upper"></div>
              {offer_benefits?.bonuses
                ?.filter(({ is_current }) => is_current)
                ?.map(({ name, id, readonly }) => (
                  <div
                    key={id}
                    className={` ${
                      offer_annual
                        ? "salary-offer__content-row__field"
                        : "salary-offer__content-row__field_extra"
                    } `}
                  >
                    {name?.length && !readonly! ? (
                      <label>{name}</label>
                    ) : (
                      <Input
                        readOnly={readOnly}
                        autoFocus
                        onChange={e =>
                          handleChangeNewField(id!, e?.target?.value, "name")
                        }
                        onKeyDown={e => {
                          if (e.key === "Enter") {
                            removeField(id!);
                          }
                        }}
                        value={name || ""}
                        placeholder="Value here..."
                        size="large"
                      />
                    )}
                    {!readOnly && (
                      <div
                        className="salary-offer__content-row__field__remove"
                        onClick={() => removeBonus(id!)}
                      >
                        <PlusIcon fill="#000" />
                      </div>
                    )}
                  </div>
                ))}
              {!readOnly ? (
                <div
                  onClick={() => addMoreLocally(true)}
                  className="salary-offer__content-row__field--add-more"
                >
                  <PlusIcon fill="#4FAA89" />
                  <span>Add more</span>
                </div>
              ) : (
                <div className="salary-offer__content-row__field--add-more" />
              )}
            </Col>
            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div className="salary-offer__content-row__field-upper">
                {offer?.offer_position_detail?.currency?.code}
              </div>
              {offer_benefits?.bonuses
                ?.filter(({ is_current }) => is_current)
                ?.map(({ name, value, id }) => (
                  <div
                    key={id}
                    className={` ${
                      offer_annual
                        ? "salary-offer__content-row__field"
                        : "salary-offer__content-row__field_extra"
                    } `}
                  >
                    <div>
                      <Input
                        readOnly={readOnly}
                        name={name!}
                        type="text"
                        value={formatNumberWithCommas(value || "")}
                        onChange={e =>
                          handleChangeNewField(
                            id!,
                            +e.target.value.replace(/,/g, ""),
                            "value"
                          )
                        }
                        type="text"
                        placeholder="Value here..."
                        size="large"
                      />

                      {!offer_annual && (
                        <p className="pl-1">
                          Monthly:{" "}
                          {formatNumberWithCommas(
                            round(value ? value / 12 : 0)
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
            </Col>
            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div className="salary-offer__content-row__field-upper">
                  {currencyCode}
                </div>
                {offer_benefits?.bonuses
                  ?.filter(({ is_current }) => is_current)
                  ?.map(({ id, value }) => (
                    <div
                      key={id}
                      className={` ${
                        offer_annual
                          ? "salary-offer__content-row__field"
                          : "salary-offer__content-row__field_extra"
                      } `}
                    >
                      <div>
                        <Input
                          value={formatNumberWithCommas(
                            round(value! * currencyRate!) || ""
                          )}
                          readOnly
                          placeholder="Value here..."
                          size="large"
                          type="text"
                        />

                        {!offer_annual && (
                          <p className="pl-1">
                            Monthly:{" "}
                            {formatNumberWithCommas(
                              round(
                                round(value! * currencyRate!)
                                  ? round(value! * currencyRate!) / 12
                                  : 0
                              )
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
              </Col>
            ) : null}
          </Row>
        </Col>
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__header-row">
            <Col span={12}>
              <div className="sub-heading">Proposed</div>
            </Col>
          </Row>
          <Row className="salary-offer__content-row salary-offer__content-row--last">
            {/* Proposed-Cols */}

            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field-upper"></div>
              {offer_benefits?.bonuses
                ?.filter(({ is_current }) => !is_current)
                ?.map(({ name, id, readonly }) => (
                  <div
                    key={id}
                    className={` ${
                      offer_annual
                        ? "salary-offer__content-row__field"
                        : "salary-offer__content-row__field_extra"
                    } `}
                  >
                    {name?.length && !readonly! ? (
                      <label>{name}</label>
                    ) : (
                      <Input
                        readOnly={readOnly}
                        autoFocus
                        onChange={e =>
                          handleChangeNewField(id!, e?.target?.value, "name")
                        }
                        onKeyDown={e => {
                          if (e.key === "Enter") {
                            removeField(id!);
                          }
                        }}
                        value={name || ""}
                        placeholder="Value here..."
                        size="large"
                      />
                    )}
                    {!readOnly && (
                      <div
                        className="salary-offer__content-row__field__remove"
                        onClick={() => removeBonus(id!)}
                      >
                        <PlusIcon fill="#000" />
                      </div>
                    )}
                  </div>
                ))}

              {!readOnly ? (
                <div
                  onClick={() => addMoreLocally(false)}
                  className="salary-offer__content-row__field--add-more"
                >
                  <PlusIcon fill="#4FAA89" />
                  <span>Add more</span>
                </div>
              ) : (
                <div className="salary-offer__content-row__field--add-more" />
              )}
            </Col>
            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div className="salary-offer__content-row__field-upper">
                {offer?.offer_position_detail?.currency?.code}
              </div>

              {offer_benefits?.bonuses
                ?.filter(({ is_current }) => !is_current)
                ?.map(({ name, value, id }) => (
                  <div
                    key={id}
                    className={` ${
                      offer_annual
                        ? "salary-offer__content-row__field"
                        : "salary-offer__content-row__field_extra"
                    } `}
                  >
                    <div className="w-full">
                      <Input
                        readOnly={readOnly}
                        name={name!}
                        type="text"
                        value={formatNumberWithCommas(value || "")}
                        onChange={e =>
                          handleChangeNewField(
                            id!,
                            +e.target.value.replace(/,/g, ""),
                            "value"
                          )
                        }
                        placeholder="Value here..."
                        size="large"
                      />

                      {!offer_annual && (
                        <p className="pl-1">
                          Monthly:{" "}
                          {formatNumberWithCommas(
                            round(value ? value / 12 : 0)
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
            </Col>
            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div className="salary-offer__content-row__field-upper">
                  {currencyCode}
                </div>
                {offer_benefits?.bonuses
                  ?.filter(({ is_current }) => !is_current)
                  ?.map(({ id, value }) => (
                    <div
                      key={id}
                      className={` ${
                        offer_annual
                          ? "salary-offer__content-row__field"
                          : "salary-offer__content-row__field_extra"
                      } `}
                    >
                      <div className="w-full">
                        <Input
                          value={formatNumberWithCommas(
                            round(value! * currencyRate!) || ""
                          )}
                          readOnly
                          placeholder="Value here..."
                          size="large"
                          type="text"
                        />

                        {!offer_annual && (
                          <p className="pl-1">
                            Monthly:{" "}
                            {formatNumberWithCommas(
                              round(
                                value && currencyRate
                                  ? value * currencyRate / 12
                                  : 0
                              )
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>

      <Row className="salary-offer">
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row salary-offer__content-row--last">
            {/* Current-Cols */}
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div
                className={` ${
                  offer_annual
                    ? "salary-offer__content-row__field  salary-offer__content-row__field--total"
                    : "salary-offer__content-row__field_extra  salary-offer__content-row__field--total"
                } `}
              >
                <label>Total Benefits</label>
              </div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div
                className={` ${
                  offer_annual
                    ? "salary-offer__content-row__field  salary-offer__content-row__field--total"
                    : "salary-offer__content-row__field_extra  salary-offer__content-row__field--total"
                } `}
              >
                <div className="w-full">
                  <Input
                    readOnly
                    value={formatNumberWithCommas(
                      offer_benefits?.current_total_bonus || ""
                    )}
                    name="current_total_lti"
                    placeholder="Value here..."
                    size="large"
                    type="text"
                  />

                  {!offer_annual && (
                    <p className="pl-1">
                      Monthly:{" "}
                      {formatNumberWithCommas(
                        round(
                          offer_benefits?.current_total_bonus
                            ? offer_benefits?.current_total_bonus / 12
                            : 0
                        )
                      )}
                    </p>
                  )}
                </div>
              </div>
            </Col>

            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div
                  className={` ${
                    offer_annual
                      ? "salary-offer__content-row__field  salary-offer__content-row__field--total"
                      : "salary-offer__content-row__field_extra  salary-offer__content-row__field--total"
                  } `}
                >
                  <div className="w-full">
                    <Input
                      value={formatNumberWithCommas(
                        current_currency_total || ""
                      )}
                      readOnly
                      type="text"
                      placeholder="Value here..."
                      size="large"
                    />

                    {!offer_annual && (
                      <p className="pl-1">
                        Monthly:{" "}
                        {formatNumberWithCommas(
                          round(
                            current_currency_total
                              ? current_currency_total / 12
                              : 0
                          )
                        )}
                      </p>
                    )}
                  </div>
                </div>
              </Col>
            ) : null}
          </Row>
        </Col>
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row salary-offer__content-row--last">
            {/* Current-Cols */}
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div
                className={` ${
                  offer_annual
                    ? "salary-offer__content-row__field  salary-offer__content-row__field--total"
                    : "salary-offer__content-row__field_extra  salary-offer__content-row__field--total"
                } `}
              >
                <label>Total Benefits</label>
              </div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div
                className={` ${
                  offer_annual
                    ? "salary-offer__content-row__field  salary-offer__content-row__field--total"
                    : "salary-offer__content-row__field_extra  salary-offer__content-row__field--total"
                } `}
              >
                <div className="w-full">
                  <Input
                    readOnly
                    type="text"
                    name="proposed_total_lti"
                    value={formatNumberWithCommas(
                      offer_benefits?.proposed_total_bonus || ""
                    )}
                    placeholder="Value here..."
                    size="large"
                  />

                  {!offer_annual && (
                    <p className="pl-1">
                      Monthly:{" "}
                      {formatNumberWithCommas(
                        round(
                          offer_benefits?.proposed_total_bonus
                            ? offer_benefits?.proposed_total_bonus / 12
                            : 0
                        )
                      )}
                    </p>
                  )}
                </div>
              </div>
            </Col>

            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div
                  className={` ${
                    offer_annual
                      ? "salary-offer__content-row__field  salary-offer__content-row__field--total"
                      : "salary-offer__content-row__field_extra  salary-offer__content-row__field--total"
                  } `}
                >
                  <div className="w-full">
                    <Input
                      value={formatNumberWithCommas(
                        proposed_currency_total || ""
                      )}
                      readOnly
                      placeholder="Value here..."
                      size="large"
                      type="text"
                    />

                    {!offer_annual && (
                      <p className="pl-1">
                        Monthly:{" "}
                        {formatNumberWithCommas(
                          round(
                            proposed_currency_total
                              ? proposed_currency_total / 12
                              : 0
                          )
                        )}
                      </p>
                    )}
                  </div>
                </div>
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>

      <Row className="salary-offer">
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row salary-offer__content-row--last">
            {/* Current-Cols */}
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total"></div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyCode ? 14 : currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total"></div>
            </Col>
          </Row>
        </Col>
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row salary-offer__content-row--last">
            {/* Current-Cols */}
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <label>Total Benefits Difference</label>
              </div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyCode ? 14 : currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <div
                  className={`w-full px-6   text-white shadow-md my-2 font-semibold text-center text-sm outline-none border border-themeInputBorder bg-themeBlue  py-2 rounded-md`}
                >
                  {offer_benefits?.proposed_total_bonus &&
                  offer_benefits?.current_total_bonus
                    ? `${round(
                        (offer_benefits?.proposed_total_bonus /
                          offer_benefits?.current_total_bonus -
                          1) *
                          100
                      )}%` || ""
                    : "0%"}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="salary-offer">
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row salary-offer__content-row--last">
            {/* Current-Cols */}
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <label>Total Remuneration</label>
              </div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <Input
                  readOnly
                  value={formatNumberWithCommas(currentTotal || "")}
                  name="current_total_lti"
                  placeholder="Value here..."
                  size="large"
                  type="text"
                />
              </div>
            </Col>

            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                  <Input
                    value={formatNumberWithCommas(
                      round(currentTotal * currencyRate!) || ""
                    )}
                    readOnly
                    placeholder="Value here..."
                    size="large"
                    type="text"
                  />
                </div>
              </Col>
            ) : null}
          </Row>
        </Col>
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row salary-offer__content-row--last">
            {/* Current-Cols */}
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <label>Total Remuneration</label>
              </div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <Input
                  readOnly
                  name="proposed_total_lti"
                  value={formatNumberWithCommas(proposedTotal || "")}
                  // value={offer_benefits?.proposed_total_bonus || ""}
                  placeholder="Value here..."
                  size="large"
                  type="text"
                />
              </div>
            </Col>

            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                  <Input
                    value={formatNumberWithCommas(
                      round(proposedTotal * currencyRate!) || ""
                    )}
                    readOnly
                    type="text"
                    placeholder="Value here..."
                    size="large"
                  />
                </div>
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>

      <Row className="salary-offer">
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row salary-offer__content-row--last">
            {/* Current-Cols */}
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total"></div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyCode ? 14 : currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total"></div>
            </Col>
          </Row>
        </Col>
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row salary-offer__content-row--last">
            {/* Current-Cols */}
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <label>Remuneration Difference</label>
              </div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyCode ? 14 : currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <div
                  className={`w-full px-6   text-white shadow-md my-2 font-semibold text-center text-sm outline-none border border-themeInputBorder bg-themeBlue  py-2 rounded-md`}
                >
                  {finalPercentage}%
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default OfferBenefits;
